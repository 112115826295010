export const wantMenus = [
    {
        title: '需求管理', key: 'want_mag',icon:'icon iconfont icon-design', compTypes: [1,3], children: [
            { title: '需求发布', key: '/want/wantIssue',icon:'icon iconfont icon-tubiao_fabushangpin', },
            { title: '需求跟进', key: '/want/wantFollow',icon:'icon iconfont icon-qianyue-sjcs' },
        ]
    },
    {
        title: '生意管理', key: 'want_bus',icon:'icon iconfont icon-xiaoxi', compTypes: [1,2,4], children: [
            { title: '应答生意', key: '/want/joinWant',icon:'icon iconfont icon-xiaoxi', },
            { title: '需求订单', key: '/want/sellerOrder',icon:'icon iconfont icon-qianyue-sjcs',compTypes: [4], },

        ]
    },
];