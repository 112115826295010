<template>
  <a-layout class="layout">
    <!-- 用户登录后的头部 -->
    <template v-if="logedUser && logedUser.user">
      <a-layout-header class="header flex flex-align-center flex-nowarp">
        <div class="logo" @click="goto('/home')">
          <img src="../../assets/images/logo-new.png" />
          中国家居产业智联网
        </div>
        <!-- 配合对接mes门户 + flex -->
        <div class="header-right">
          <div class="hr-title">
            <CertState
              v-if="logedUser.comp && logedUser.comp.name"
              :comp="logedUser.comp"
              :type="1"
            ></CertState>
          </div>
          <!-- 配合对接mes门户 -->
          <!-- <div class="mes-menu flex-1 flex">
            <div @click="toMes(1)">
              <img src="../../assets/images/mes/yun.png">云工厂
            </div>
            <div @click="toMes(2)"><img src="../../assets/images/mes/cyjs.png">产业集市</div>
            <div @click="toMes(3)"><img src="../../assets/images/mes/gxzx.png">共享中心</div>
          </div> -->
          <div class="hr-menu">
            <a-menu
              v-if="navs && navs.length > 0 && defaultSelectedNavKeys"
              theme="dark"
              mode="horizontal"
              :selectedKeys="defaultSelectedNavKeys"
              class="nav-func-menu"
            >
              <a-menu-item
                v-for="nav in navs"
                :key="nav.key"
                @click="clickNav(nav)"
                >{{ nav.name }}</a-menu-item
              >
            </a-menu>
          </div>

          <div class="hr-head flex">
            <a-menu
              mode="horizontal"
              theme="dark"
              class="nav-user-menu"
              @openChange="toggleHeaderUserNavCollapsed"
            >
              <template v-if="logedUser && logedUser.user">
                <a-sub-menu key="sub1">
                  <span class="menu_head" slot="title">
                    <div class="header-img">
                      <img
                        :src="
                          logedUser.user.avatar
                            ? imgUrl + logedUser.user.avatar
                            : require('../../assets/images/user.png')
                        "
                      />
                    </div>
                    <span>{{ logedUser.user.name || "" }}</span>
                    <span style="margin-left: 5px" v-if="headerUserNavCollapsed"
                      >&#9650;</span
                    >
                    <span style="margin-left: 5px" v-else>&#9660;</span>
                  </span>
                  <a-menu-item key="1-1" @click="gotoPersonSet()"
                    >个人设置</a-menu-item
                  >
                  <!-- <a-menu-item key="1-1" @click="openModal('个人设置')">个人设置</a-menu-item> -->
                  <!-- <a-menu-item key="1-2" @click="openModal('修改手机')">修改手机</a-menu-item>
                  <a-menu-item key="1-3" @click="openModal('修改密码')">修改密码</a-menu-item>-->
                  <a-menu-item key="1-2" @click="goto('/contactInfo')"
                    >联系平台</a-menu-item
                  >
                  <a-menu-divider />
                  <a-menu-item key="1-4" @click="logout">退出登录</a-menu-item>
                </a-sub-menu>
              </template>
              <template v-else>
                <a-menu-item key="2" @click="goto('/login')">登录</a-menu-item>
                <a-menu-item key="3" @click="goto('/register')"
                  >注册</a-menu-item
                >
              </template>
            </a-menu>
          </div>
          <a
            :href="PORTAL_URL + '/#/home'"
            style="float: right; margin-right: 60px; color: #fff"
            >返回首页</a
          >
        </div>
      </a-layout-header>
    </template>
    <!-- 用户未登录的头部 -->
    <template v-else>
      <div class="header flex flex-align-center flex-nowarp new-header">
        <div class="logo">
          <img src="../../assets/images/logo-new.png" />
          中国家居产业智联网
        </div>
        <div class="container flex flex-pack-justify">
          <div class="flex-1 flex menu-bar">
            <!-- <div class="btn" :class="{active: $route.path === '/home'}" @click="goto('/home')">首页</div>
            <div
              class="btn"
              :class="{active: $route.path === '/cloudWorks'}"
              @click="goto('/cloudWorks')"
            >云工厂</div>
            <div
              class="btn"
              :class="{active: $route.path === '/industry'}"
              @click="goto('/industry')"
            >产业集市</div>
            <div
              class="btn"
              :class="{active: $route.path === '/share'}"
              @click="goto('/share')"
            >共享产能</div>
            <div
              class="btn"
              :class="{active: $route.path === '/about'}"
              @click="goto('/about')"
            >关于我们</div> -->
            <div class="btn" @click="toPortal('/home')">首页</div>
            <div class="btn" @click="toPortal('/tradingCenter')">
              销售物流中心
            </div>
            <div class="btn" @click="toPortal('/designCenter')">
              创新设计中心
            </div>
            <div class="btn" @click="toPortal('/woodCenter')">木材交易中心</div>
            <div class="btn" @click="toPortal('/intelligentCenter')">
              智能制造中心
            </div>
            <div class="btn" @click="toPortal('/financialCenter')">
              金融服务中心
            </div>
            <!-- <div class="btn" @click="toFinancialCenter">
              金融服务中心
            </div> -->
          </div>
          <div
            class="flex log-bar flex-align-center"
            style="margin-right: auto; padding-right: 80px"
          >
            <div class="btn" style="margin-right: 30px" @click="goto('/login')">
              登录
            </div>
            <div class="btn bordered" @click="goto('/register')">注册</div>
          </div>
        </div>
        <!-- <div class="flex-1 flex menu-bar">
                    <div class="btn">首页</div><div class="btn">云工厂</div><div class="btn">产业集市</div><div class="btn">共享产能</div><div class="btn">关于我们</div>
                </div>
                <div class="flex log-bar" style="margin-right:auto;padding-right: 80px;">
                    <div class="btn" style="margin-right:30px;" @click="goto('/login')">登录</div>
                    <div class="btn" @click="goto('/register')">注册</div>
        </div>-->
      </div>
    </template>
    <a-layout
      class="layout-page"
      :class="{ nopadding: !(logedUser && logedUser.user) }"
    >
      <!-- 边栏 -->
      <a-layout-sider
        v-if="menus && menus.length > 0"
        width="240"
        class="layout-sider"
        collapsible
        v-model="siderCollapsed"
      >
        <a-menu
          :selectedKeys="leftMenu_defaultOpenKeys"
          :openKeys.sync="leftMenu_defaultOpenKeys"
          style="margin-bottom: 48px"
          mode="inline"
          theme="dark"
          @click="clickItem"
        >
          <template v-for="item in menus">
            <a-menu-item v-if="!item.children" :key="item.key">
              <span
                :class="[item.icon ? item.icon : 'icon iconfont icon-yanjing']"
              ></span>
              <span>{{ item.title }}</span>
            </a-menu-item>
            <sub-menu :menu-info="item" :key="item.key" />
          </template>
        </a-menu>
      </a-layout-sider>
      <a-layout style="padding: 0">
        <a-layout-content
          class="layout-content flex flex-v"
          style="height: 100%"
        >
          <div v-if="$route.meta.title" class="zt-page-head">
            {{ $route.meta.title }}
          </div>
          <div
            class="flex-1"
            style="overflow: hidden; overflow-y: auto; width: 100%"
          >
            <router-view />
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <!-- 弹出框 -->
    <!-- <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
      <Login v-if="popTitle == '用户登录'" @success="popVisible = false" @close="popVisible = false"></Login>
      <Config v-if="popTitle == '个人设置'" @success="popVisible = false"></Config>
      <ModMp v-if="popTitle == '修改手机'" @success="popVisible = false"></ModMp>
      <ModPwd v-if="popTitle == '修改密码'" @success="popVisible = false"></ModPwd>
    </el-dialog>-->
    <SurveyStat
      v-if="logedUser && logedUser.comp && logedUser.comp.comp_type == 1"
    ></SurveyStat>
  </a-layout>
</template>
<script>
import SubMenu from "./SubMenu";
import { getNavs } from "./menu";
import { logOut } from "@/service/account";
import { loginOut } from "@/service/mood";
import { getLocalStorge } from "@/service/common";
import {
  IMG_URL_PRE,
  MES_PORTAL_URL,
  PORTAL_URL,
  FINANCIAL_CENTER_URL,
} from "@/config";
import CertState from "./StateCert.vue";
import SurveyStat from "./SurveyStat.vue";
export default {
  components: {
    "sub-menu": SubMenu,
    CertState,
    SurveyStat,
  },
  data() {
    return {
      fromAuth: "",
      PORTAL_URL,
      imgUrl: IMG_URL_PRE,
      menus: null,
      siderCollapsed: false,
      headerUserNavCollapsed: false,
      defaultSelectedNavKeys: [],
      leftMenu_defaultOpenKeys: [],
      matchItems: [],
      comp: null,
    };
  },
  watch: {
    logedUser: function (newVal) {
      if (!newVal) {
        this.menus = null;
      }
    },
    $route: {
      handler: function (val, oldVal) {
        this.fromAuth = this.$route.query?._from === "auth"; //是否从 admin 授权跳转登录
        this.refreshMenu();
      },
      // 深度观察监听
      deep: true,
    },
  },
  computed: {
    logedUser() {
      return this.$store.state.loginUser;
    },
    navs() {
      const navs = getNavs();
      if (this.fromAuth) {
        const navAuthMes = navs.filter((nav) => nav.key === "/mes");
        return navAuthMes;
      } else {
        return navs;
      }
    },
  },
  created: async function () {
    this.fromAuth = this.$route.query?._from === "auth"; //是否从 admin 授权跳转登录
    this.refreshMenu();
  },
  mounted: async function () {},
  methods: {
    // 配合对接mes门户
    // toMes(type) {
    //   switch (type) {
    //     case 1:
    //       // window.open(MES_PORTAL_URL+"/cloudWorks?p_token="+this.logedUser.token);
    //       location.href = MES_PORTAL_URL+"/cloudWorks?p_token="+this.logedUser.token;
    //       break;
    //     case 2:
    //       // window.open(MES_PORTAL_URL+"/purchase?p_token="+this.logedUser.token);
    //       location.href = MES_PORTAL_URL+"/cloudWorks?p_token="+this.logedUser.token;
    //       break;
    //     case 3:
    //       // window.open(MES_PORTAL_URL+"/share?s=prepare&p_token="+this.logedUser.token);
    //       location.href = MES_PORTAL_URL+"/cloudWorks?p_token="+this.logedUser.token;
    //       break;
    //   }
    // },
    // 跳转门户五大中心
    toPortal(path) {
      location.href = PORTAL_URL + "/#" + path;
    },
    // 跳转金融服务中心
    toFinancialCenter() {
      location.href = FINANCIAL_CENTER_URL;
    },
    async logout() {
      const loginUser = await getLocalStorge("logedUser");
      loginOut(loginUser.token);
      logOut().then();
    },
    async clickNav(nav) {
      if (nav.key && nav.key.indexOf("/") == 0) {
        this.$router.push(nav.key);
      }
      this.menus = nav.menus;
    },
    clickItem(item) {
      if (item.key && item.key.indexOf("/") == 0) {
        this.$router.push(item.key);
      }
    },
    toggleHeaderUserNavCollapsed() {
      this.headerUserNavCollapsed = !this.headerUserNavCollapsed;
    },
    goto(path) {
      if (this.fromAuth && path === "/home") return; //是否从授权页面跳转且想去主页查看数据
      // this.menus = null;
      console.log("path", path);
      this.$router.push(path);
    },
    gotoPersonSet() {
      this.goto(`/personSet/${this.$store.state.loginUser.user.id}`);
    },
    refreshMenu() {
      this.matchItems = [];
      this.digMenu(this.$route.path, this.navs, [], 0);
      if (this.matchItems.length > 0) {
        this.defaultSelectedNavKeys = this.matchItems.sort((a, b) => {
          return b.length - a.length;
        })[0];
      }
      if (
        this.defaultSelectedNavKeys &&
        this.defaultSelectedNavKeys.length > 0
      ) {
        this.leftMenu_defaultOpenKeys = this.defaultSelectedNavKeys.slice(1);
        let curNav = this.navs.find((r) => {
          return r.key == this.defaultSelectedNavKeys[0];
        });
        if (curNav) this.menus = curNav.menus;
      }
    },
    digMenu(key, parentMenus, keys, level) {
      if (!(parentMenus && parentMenus.length > 0)) return;
      let curMenu;
      for (let menu of parentMenus) {
        keys[level] = menu.key;
        if (menu.key == key || key.indexOf(menu.key) == 0) {
          this.matchItems.push([...keys.slice(0, level + 1)]);
        } else {
          this.digMenu(key, menu.menus || menu.children, keys, level + 1);
        }
      }
    },
  },
};
</script>
<style>
.container {
  width: 1200px;
  margin: 0 auto;
}
.nav-func-menu,
.nav-user-menu {
  background: none;
}

.ant-menu-submenu-popup .iconfont + span {
  margin-left: 10px;
}

.nav-func-menu .ant-menu-item,
.nav-user-menu .ant-menu-item {
  height: 100%;
  line-height: 60px;
}

.nav-func-menu .ant-menu-item-selected {
  background: rgba(249, 249, 249, 0.1) !important;
  /* opacity:0.1; */
  /* color: rgba(213, 160, 93, 1); */
  color: #e6e6e6;
}

.nav-user-menu .ant-menu-item-selected {
  background-color: transparent !important;
}

.nav-user-menu .ant-menu-submenu {
  height: 100%;
  border-bottom: none !important;
}

.nav-user-menu .ant-menu-submenu-title {
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-user-menu .ant-menu-submenu-content {
  margin-top: 0;
}
</style>
<style scoped>
/* 配合对接mes门户 */
/* .mes-menu {
  min-width: 370px;
} */
.mes-menu > div {
  display: inline-block;
  vertical-align: top;
  padding: 0 20px;
  height: 60px;
  text-align: center;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  /* margin-right: 20px; */
  cursor: pointer;
  transition: all 0.2s linear;
}
.mes-menu > div:hover {
  background: rgba(249, 249, 249, 0.1);
  color: #fff;
}
.mes-menu > div img {
  width: 20px;
  position: relative;
  top: -1px;
  margin-right: 10px;
}
</style>
<style scoped>
.layout {
  height: 100%;
  /* 配合对接mes门户 1500px */
  min-width: 1500px;
  overflow: auto;
}
.header {
  height: 60px;
  padding: 0;
  /* position: fixed; */
  /* 适应手机端左右滑动 */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* 配合对接mes门户 1500px */
  min-width: 1500px;
  z-index: 100;
  background: #5074ee;
}
.header-right {
  width: calc(100% - 240px);
  height: 60px;
}
.header-right:after {
  clear: both;
}
.header-right > div {
  display: inline-block;
  vertical-align: top;
}
.hr-title {
  line-height: 60px;
  margin-right: 4%;
  margin-left: 3%;
}
.logo {
  width: 240px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
  color: rgba(249, 249, 249, 1);
  /* border-right:1px solid rgba(71,101,221,1); */
  background: url("../../assets/images/logo_bg.png") no-repeat center center;
}
.logo img {
  height: 40px;
  margin-right: 10px;
}
.hr-menu {
  height: 60px;
  line-height: 60px;
  /* 配合对接mes门户 */
  /* margin-right: 8%; */
}
.hr-head {
  height: 60px;
  line-height: 60px;
  float: right;
  margin-right: 20px;
}
.hr-head .menu_head {
  display: inline-block;
}
.hr-head .ant-menu-horizontal {
  /* width: 200px; */
  height: 60px;
  line-height: 60px;
  padding-left: 8%;
}
.nav-btn {
  padding: 17px 15px;
  color: #fff;
  cursor: pointer;
}

.layout-page {
  padding-top: 60px;
}
.layout-page.nopadding {
  padding-top: 0;
}

.layout-content {
  margin: 0;
  min-height: 280px;
  overflow: hidden;
  overflow-y: auto;
  /* background: url('../../assets/images/reg_bg.jpg') no-repeat center center; */
}
.layout-page >>> .layout-sider.ant-layout-sider {
  background: #414b5b;
}
.layout-page >>> .ant-layout-sider-children {
  overflow-y: auto;
}
.layout-page >>> .ant-menu-dark,
.layout-page >>> .ant-layout-sider-trigger {
  background: #414b5b;
}
.layout-page >>> .ant-layout-sider-trigger {
  /* 适配手机端左右滑动 */
  position: absolute;
}
.layout-page >>> .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #414b5b;
}
/* div>>> ul.ant-menu-dark,div>>> .ant-menu-dark .ant-menu-sub {
        background: #414B5B;
    } */
.layout-sider {
  overflow: auto;
  /* height: 100vh; */
  /* position: fixed; */
  /* left: 0; */
  /* top: 0; */
  /* padding-top: 80px; */
}
.layout-sider >>> .iconfont + span {
  margin-left: 10px;
}
.layout-sider >>> .ant-menu-inline-collapsed .iconfont + span {
  display: none;
}
.layout-sider >>> .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #5074ee;
}
.header-img {
  width: 42px;
  height: 42px;
  position: absolute;
  left: -40%;
  top: 8px;
}

.header-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  vertical-align: top;
}

.layout >>> .el-dialog {
  min-width: 400px;
}

.new-header {
  /* background: linear-gradient(
    360deg,
    rgba(0, 70, 162, 0.5) 0%,
    rgba(0, 72, 170, 0.5) 100%
  ); */
  background: #5074ee;
  overflow: hidden;
}
.new-header .btn {
  padding: 0 15px;
  line-height: 60px;
  color: #fff;
  cursor: pointer;
  border-radius: 16px;
}
.new-header .btn:hover {
  color: #1dffdc;
}
.new-header .menu-bar .btn {
  margin: 0 15px;
  font-size: 16px;
}
.new-header .menu-bar .btn.active {
  color: #1dffdc;
}
.new-header .log-bar .btn {
  width: 84px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}
.new-header .log-bar .btn.bordered {
  border: 1px solid #fff;
}
.nav-user-menu .menu_head i {
  display: inline-block;
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  margin-left: 5px;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}
.nav-user-menu:hover .menu_head i {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -webkit-transform: rotate(270deg); /* Safari and Chrome */
}

/*--------------滚动条样式----------------*/
.layout >>> .ant-layout-sider-children::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.layout >>> .ant-layout-sider-children::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  -webkit-border-radius: 6px;
}
.layout >>> .ant-layout-sider-children::-webkit-scrollbar-thumb:vertical {
  height: 3px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  -webkit-border-radius: 6px;
}
.layout >>> .ant-layout-sider-children::-webkit-scrollbar-thumb:horizontal {
  width: 3px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 6px;
  -webkit-border-radius: 6px;
}

@media (max-width: 980px) {
  .hr-title {
    display: none;
  }
}
</style>
