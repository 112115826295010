import { MOOD_URL } from '../config';
import { _postServer } from './common';
/**---------------------------木材中心----------------------------------
 * 退出回调通知
 * @param {*} token
 */
export async function loginOut(token) {
    return await _postServer(MOOD_URL, "/api/Thirdauth/loginOut", {
        token
    });
}