export const agentMenus = [
    {
        title: '分销管理', key: 'agent_mgr',icon:'icon iconfont icon-qianyue-sjcs',compTypes:[1,3], children: [
            { title: '分销商品库', key: '/agent/agentGoods',icon:'icon iconfont icon-nengli', },
            { title: '代理商设置', key: '/agent/agentSet',icon:'icon iconfont icon-lujing27', },
            { title: '分销订购管理', key: '/agent/agentOrder',icon:'icon iconfont icon-lujing996',compTypes:[3], },
            { title: '商品分类', key: '/agent/txgl',icon:'icon iconfont icon-yixuantaoxi',compTypes:[3], },
            // { title: '数据统计', key: '/agent/agentData',icon:'icon iconfont icon-lujing997',compTypes:[1,3], },
        ]
    },
];