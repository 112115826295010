<template>
    <div ref="frame">
        <div>

        </div>
    </div>
</template>

<script>
    import { surveyGet } from '@/service/mobile';
    export default {
        components: {
            
        },
        props: ['comp'],
        data() {
            return {
                survey: null,
                isMobile:false
            }
        },
        watch: {},
        computed: {},
        async created () {
            // 备料调研问卷入口
            // this.refreshItems();
        },
        mounted(){
            this.isMobile = (document.body.clientWidth || document.documentElement.clientWidth)>600;
            // console.log("isMobile",this.isMobile);
        },
        methods: {
            refreshItems() {
                surveyGet().then(rst => {
                    // console.log("survey",rst);
                    this.survey = rst;
                    if (this.isMobile) {
                        if (rst && rst.step < 4 || !rst) {
                            this.$confirm('确认是否去完成调研?', '金融备料调查问卷', { confirmButtonText: '确定',
                            cancelButtonText: '取消', type: 'warning',
                            }).then(() => {
                                this.$router.push("/mobile/survey");
                            });
                        }
                    } else {
                        if (rst && rst.step < 4 || !rst) {
                            this.$router.push("/mobile/survey"); 
                        }
                    }
                })
            },
        }
    }
</script>
<style scoped>
    .welcome-txt {
        font-weight: 500;
        color: #CCCCCC;
        font-size: 16px;
        /* margin-right: 80px;
        margin-left: 30px; */
    }
    .welcome-txt>span {
        cursor: pointer;
    }
</style>